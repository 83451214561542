import axios, { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { apiUrl } from './set_api';


// Define the shape of the response from the server
interface LoginResponse {
    access_token: string;
}

// Define the structure of the decoded JWT payload
interface JwtPayload {
    exp: number;  // Expiration time in seconds since the epoch
}

// Define the function with type annotations
export const login = async (email: string, password: string): Promise<boolean> => {
    try {
        
        const credentials = new FormData()
        credentials.append('grant_type', 'password')
        credentials.append('username', email)
        credentials.append('password', password)

        const response: AxiosResponse<LoginResponse> = await axios.post(`${apiUrl}/users/verify_user`, credentials, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        });

        // Extract the token from the response
        const token = response.data.access_token;

        if (token) {
            // Store the token in a cookie with a secure flag if on HTTPS
            Cookies.set('token', token);  // Set secure: true if using HTTPS
            return true;
        } else {
            console.error("Token not found in response.");
            return false;
        }

    } catch (error) {
        console.error("Login failed:", error);
        return false;
    }
};

export const checkAuth = async (): Promise<{ isAuthenticated: boolean; email?: string }> => {
    try {
        // Get the token from cookies
        const token = Cookies.get('token');

        if (!token) {
            return { isAuthenticated: false };
        }

        // Make a request to the check_auth endpoint
        const response = await axios.get(`${apiUrl}/users/check_auth`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return { isAuthenticated: true, email: response.data.email };
    } catch (error) {
        console.error("Authentication check failed:", error);
        return { isAuthenticated: false };
    }
};

export const isTokenExpired = (token: string): boolean => {
    try {
        const decoded: JwtPayload = jwtDecode<JwtPayload>(token);
        const currentTime = Date.now() / 1000;  // Current time in seconds
        return decoded.exp < currentTime;
    } catch (error) {
        console.error("Failed to decode token:", error);
        return true;  // Consider token expired if decoding fails
    }
};